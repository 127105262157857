<template>
  <div class="vouch">
    <breadcrumb :link-list="breadcrumbList"></breadcrumb>
    <div class="header">
      <div class="headTitle">
        <img class="headIcon" src="@/assets/image/AccountDeal/gameSelectHead/vouchIcon.png">
        <div class="headText">{{ step === 1 ? '担保中介' : '担保中介 - 收费明细' }}</div>
        <img class="headTip" v-if="step===1" src="@/assets/image/AccountDeal/gameSelectHead/vouchTip.png">
      </div>
      <router-link :to="{name:'Home'}" class="headBack" v-if="step===1">返回首页</router-link>
      <div class="headBack" v-else @click="backBtn">返回</div>
    </div>
    <!--  游戏列表 gameList -->
    <div class="gameList" v-if="step===1">
      <gameList :gameClick="gameClick" other vouch></gameList>
    </div>
    <!--  游戏列表 gameList -->
    <div class="gameInfo" v-if="step===2">
      <div class="select">
        <img :src="selectGame.game_image" alt="" class="img">
        <div class="info">
          <div class="name">{{ selectGame.game_name }}</div>
          <div class="cost">{{ selectGame.media_guarantee }}</div>
        </div>
<!--        <a target="_blank" class="btn" @click="$accessLink(selectGame.sell_customer)"-->
<!--           :href="`${selectGame.sell_customer}?u_cust_id=${$store.state.userData.user_id}&u_cust_name=${$store.state.userData.nickname}`">联系客服</a>-->
      </div>
      <div class="wechatList" v-if="selectGame.wechat_list.length>0">
        <div class="title">
          <span><img src="@/assets/image/Home/sellPop/vouchIcon1.png" alt="">微信扫码</span>
          或点击卡片
          <span><img src="@/assets/image/Home/sellPop/vouchIcon2.png" alt="">复制微信号</span>
          ，添加官方客服快速咨询～
        </div>
        <div class="wehcatBox">
          <div class="wechatItem" v-for="(item,index) in selectGame.wechat_list" :key="index" @click="copyWechat(item.wechat_number)">
            <div class="wechatType">{{item.type===0?'客服微信':'企业微信'}}</div>
            <img class="wechatImg" :src="item.imgurl" alt="">
            <div class="wechatName">{{item.wechat_name}}</div>
            <div class="wechatNumber" v-if="item.type===0" >
              <div>微信号：{{item.wechat_number}}</div>
              <span><img src="@/assets/image/Home/sellPop/vouchIcon3.png" alt="">点击复制</span>
            </div>
            <input type="text" id="input"/>
            <div class="wechatTip" style="background: #ffa45a" v-if="item.status === 1">营业中</div>
            <div class="wechatTip" v-else-if="item.status === 2">歇业中</div>
            <div class="wechatTip" v-else-if="item.status === 3">冻结中</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="title">
          <span></span>
          交易说明
        </div>
        <div class="ql-container ql-snow">
          <p class="content ql-editor" v-html="selectGame.media_guarantee_trade_show"></p>
        </div>
      </div>

    </div>

  </div>
</template>
<script>

import breadcrumb from "@/components/breadcrumb";
import gameList from "@/components/gameList/index"

export default {
  name: 'vouch',
  props: {},
  components: {
    breadcrumb,
    gameList
  },
  data() {
    return {
      // gameList 、 merchantList 、 merchantInfo
      step: 1,
      // 面包屑
      breadcrumbList: [
        {name: '我要卖'},
        {link: 'Vouch', name: '担保中介'}
      ],
      // 选择的游戏
      selectGame: '',
    }
  },
  methods: {
    /**
     * 点击游戏事件
     * @param game
     */
    gameClick(game) {
      this.selectGame = game
      this.step++
      this.$router.push({
        path: '/vouch',
        query: {
          game_id: game.game_id,
        },
      })
    },
    /**
     * 返回到游戏列表
     */
    backBtn() {
      this.step--
      this.$router.push({
        path: '/vouch',
      })
    },

    /**
     * 复制微信号
     * @param text 要复制的文本
     */
    copyWechat(text) {
      var text = text;
      var input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand('copy'); // 执行浏览器复制命令
      this.$message({
        type: "success",
        center: true,
        message: '复制成功'
      })
    },
  },
  mounted() {

  },
  watch: {},
  computed: {}
}
</script>

<style lang='less' scoped>
.ql-container {
  border: none !important;
}

.vouch {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;

  .header {
    width: calc(1200px - 88px);
    height: 70px;
    background: linear-gradient(90deg, #163279 0%, rgba(0,8,77,0.8000) 100%);
    border-radius: 10px 10px 0 0;
    padding: 0 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      display: flex;
      flex-direction: row;
      align-items: center;

      .headText {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: CENTER;
        color: #ffffff;
        margin: 0 29px 0 8px;
      }
    }

    .headBack {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .introduce {
    margin: 0 0 18px;

    .introduceHead {
      margin: 18px 0 4px;
    }

    .introduceBody {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .IB_card {
        margin: 3px 0 0;
        width: 640px;
        height: 144px;
        padding: 23px 34px 23px 43px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .card_title {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #ff9407;
          line-height: 20px;

          span {
            display: inline-block;
            width: 4px;
            height: 14px;
            background: #ff9407;
            margin: 0 14px 0 0;
          }
        }

        .card_text {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #5b5b5b;
          line-height: 25px;
        }

        .card_tip {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #ff9100;
          line-height: 16px;
        }
      }

      img {
        cursor: pointer;
      }
    }
  }

  .gameInfo {
    width: calc(100% - 40px);
    padding: 30px 20px 100px;
    background: #FFFFFF;

    .select {
      padding: 0 0 30px 18px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .img {
        width: 80px;
        height: 80px;
        margin: 0 25px 0 0;
        border-radius: 20px;
      }

      .info {
        margin: 0 100px 0 0;

        .name {
          font-size: 24px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #4a4a4a;
          margin: 0 0 5px;
        }

        .cost {
          max-width: 600px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #4a4a4a;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .btn {
        width: 160px;
        height: 40px;
        line-height: 40px;
        background: #ffdb51;
        border-radius: 30px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: CENTER;
        color: #000000;
        cursor: pointer;
      }


    }

    .wechatList {
      margin: 5px 0 25px;

     > .title {
        display: flex;
        align-items: center;
        padding: 9px 23px;
        background: #ffe6d7;
        border-radius: 10px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #e57d28;
        margin: 0 0 14px;
        span {
          margin: 0 7px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #9e3a00;
          display: flex;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
            margin: 0 7px 0 0 ;
          }
        }
      }

      .wehcatBox{
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        .wechatItem{
          width: 224px;
          height: 272px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          margin: 14px 4px 10px;
          padding:  10px 0;
          border-radius: 10px;
          box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10);
          position: relative;
          .wechatType{
            height: 34px;
            width: 125px;
            text-align: center;
            line-height: 34px;
            margin: 0 0 7px;
            border-radius: 10px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #000000;
          }
          .wechatImg{
            width: 150px;
            height: 150px;
          }
          .wechatName{
            width: 150px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            color: #ffffff;
            margin: 0 0 15px;
            background: #98c785;
          }
          .wechatNumber{
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #000000;
            line-height: 14px;
            width: 150px;
            text-align: center;
            span{
              display: block;
              text-align: center;
              min-width: 72px;
              margin-top: 10px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #000000;
              line-height: 14px;
              img{
                width: 13px;
                height: 13px;
                margin: 0 3px 0 0;
              }
            }
          }
          .wechatTip{
            position: absolute;
            width: 63px;
            height: 22px;
            line-height: 22px;
            background: #cecece;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: CENTER;
            color: #ffffff;
            border-radius: 10px 0 10px 0;
            top: 0;
            left: 0;
          }

        }
      }

    }

    .card {
      padding: 28px 0 29px 32px;
      border-radius: 10px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);

      .title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #000;
        margin: 0 0 24px;

        span {
          display: inline-block;
          width: 4px;
          height: 14px;
          background: #000;
          margin: 0 14px 0 0;
        }
      }

      .content {
        .item {
          display: flex;
          margin: 0 0 13px;

          .square {
            width: 9px;
            height: 9px;
            background: #515151;
            margin: 7px 14px 0 0;
          }

          .text {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #5b5b5b;

            span {
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #ff9100;
            }
          }

        }
      }
    }
  }
  #input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -10;
  }

}
</style>
